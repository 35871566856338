.login-bkgrnd {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(/images/login-background.jpg), linear-gradient(140deg, rgba(186,105,35,1) 0%, rgba(157,76,36,1) 35%, rgba(77,31,8,1) 100%);
  background-size: cover;
  background-position: center;
  transform: scaleX(-1)
}

.login-cover {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.2) 65%,
    rgba(0, 0, 0, 0.5) 90%
  );
}

.login-heading {
  color: #fff;
  text-shadow: 0 0 4px #000;
  margin: 0 auto 30px;
  flex: 0 0 20px;
  position: relative;
  z-index: 1;
  font-weight: 400;
  font-size: 16px;
}

@media screen and (max-width: 840px) {
  .login-heading {
    display: none;
  }
}
.login-card {
  padding: 20px;
  width: 350px;
  background: #fff;
  text-align: center;
  position: relative;
  z-index: 1;
  justify-content: flex-start;
  display: flex;
  height: auto;
}

@media screen and (max-width: 500px) {
  .login-card {
    max-width: calc(100vw - 20px);
    padding: 10px;
  }
}
.facebook-btn:not(:disabled),
.google-btn:not(:disabled),
.apple-btn:not(:disabled) {
  transition: background-color 0.3s ease-out;
  color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  justify-content: center;
  font-weight: 700;
}

.facebook-btn:not(:disabled) span,
.google-btn:not(:disabled) span,
.apple-btn:not(:disabled) span {
  flex: 1;
}

.google-btn:not(:disabled) {
  color: #fff;
  background-color: #d84a38;
}

.google-btn {
  font-size: 1.2rem;
  padding: 9px 14px 6px;
  font-family: var(--fontHeading);
  cursor: pointer;
  letter-spacing: 0.04em;
  padding: 7px 14px;
  border-radius: 20px;
}

.apple-btn:not(:disabled) {
  color: #fff;
  background-color: black;
  background-position: center;
}

.google-btn:hover {
  background-color: #c03c2b;
}

.forgot-password-card {
  max-width: 500px;
  margin: auto;
  text-align: left;
}

.forgot-password {
  max-width: calc(100% - 24px);
  margin: 62px auto 220px;
  padding: 40px 0 80px;
  overflow: auto;
  min-height: 600px;
}
